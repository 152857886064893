import { AccordionAreaDeprecated, Anchor, Text } from '@codecademy/gamut';
import { MiniChevronDownIcon } from '@codecademy/gamut-icons';
import { css, states, transitionConcat } from '@codecademy/gamut-styles';
import { StyleProps } from '@codecademy/variance';
import { Scale } from '@codecademy/variance/dist/types/config';
import styled from '@emotion/styled';
import * as React from 'react';

import { LayoutMenuSection } from './LayoutMenuSection';

const StyledAccordionArea = styled(AccordionAreaDeprecated)`
  padding-bottom: ${({ theme }) => theme.spacing[32]};
  position: relative;
  left: -4px;
`;

const chevronStates = states({
  expanded: { transform: 'rotate(180deg)' },
});

const ExpandChevron = styled(MiniChevronDownIcon)<
  StyleProps<typeof chevronStates>
>(
  css({
    transform: 'rotate(0deg)',
    transition: transitionConcat(['transform'], 'slow', 'ease-out'),
  }),
  chevronStates
);

export type SectionItem = {
  title: string;
  slug: string;
  href: string;
  onClick: (event: React.MouseEvent) => void;
  badge?: JSX.Element;
};

export type Section = {
  title: string;
  slug: string;
  items: SectionItem[];
};

export type AccordionMenuProps = {
  section: Section;
  onSectionToggle: (sectionSlug: string) => void;
  onItemClick: () => void;
  selectedItem?: string;
  verticalSpacing?: Scale<{
    readonly property: 'padding';
    readonly scale: 'spacing';
  }>;
};

export const AccordionMenu: React.FC<AccordionMenuProps> = ({
  section,
  onSectionToggle,
  onItemClick,
  selectedItem,
  verticalSpacing,
}) => {
  const [expanded, setExpanded] = React.useState(true);

  return (
    <StyledAccordionArea
      expanded={expanded}
      top={
        <Anchor
          variant="interface"
          py={12}
          px={4}
          onClick={() => {
            onSectionToggle(section.slug);
            setExpanded((prev) => !prev);
          }}
          aria-expanded={expanded}
          display="flex"
        >
          <Text variant="title-xs" style={{ flex: 1 }} textAlign="left">
            {section.title}
          </Text>
          <ExpandChevron
            ml={12}
            size={14}
            expanded={expanded}
            position="relative"
            top={4}
          />
        </Anchor>
      }
    >
      <LayoutMenuSection
        items={section.items}
        selectedItem={selectedItem}
        onItemClick={onItemClick}
        verticalSpacing={verticalSpacing}
      />
    </StyledAccordionArea>
  );
};
