import { BlockChildrenProps } from '../../ContentMarkdown/types';
import { BaseBlock } from './BaseBlock';

export const ErrorBlockVariant: React.FC<BlockChildrenProps> = ({
  children,
}) => (
  <BaseBlock bg="black" dataTestId="error-block" textColor="red-500">
    {children}
  </BaseBlock>
);
