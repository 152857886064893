import { FlexBox, GridBox, Text } from '@codecademy/gamut';
import { MiniArrowRightIcon } from '@codecademy/gamut-icons';
import React from 'react';

import { formatNumberUS } from './helpers';

export const ExperienceValuesWithProgress: React.FC<{
  experienceProgress: number;
  experience: number;
  highestExperienceDigits: number;
}> = ({ experienceProgress, experience, highestExperienceDigits }) => {
  const experienceCopy = `${formatNumberUS(experience)} XP`;
  const experienceProgressCopy = `${formatNumberUS(
    experience + (experienceProgress ?? 0)
  )} XP`;

  // Number of digits in the highest experience value plus 3 for the ' XP' suffix
  const highestCharacterCount = highestExperienceDigits + 3;

  return (
    <GridBox
      gridTemplateColumns={`1fr min-content ${highestCharacterCount * 9}px`}
      height="100%"
      alignItems={{ _: 'flex-end', sm: 'center' }}
      pr={16}
    >
      <Text as="p" variant="p-base" textAlign="right">
        {experienceCopy}
      </Text>
      <FlexBox
        justifyContent="center"
        px={12}
        mb={{ _: 4, sm: 0 }}
        aria-label="increased to"
      >
        <MiniArrowRightIcon size={16} />
      </FlexBox>
      <Text as="p" variant="p-base" color="yellow-500" textAlign="right">
        {experienceProgressCopy}
      </Text>
    </GridBox>
  );
};
