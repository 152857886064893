import { LayoutMenu, SectionItemLink, SelectedSectionItem } from '@mono/brand';
import { UserClickData } from '@mono/tracking';
import { useRouter } from 'next/router';

import { LayoutMenuSection } from './types';

export type SectionsLayoutMenuProps = {
  /**
   * Text shown in mobile button that opens flyout on click
   */
  mobileLabel: string;
  pageName: string;
  rootPath: string;
  rootPageName: string;
  /**
   * Text shown for root link. Think "All X" or "X Home"
   */
  rootTitle: string;
  sections: LayoutMenuSection[];

  /**
   * A selected item slug. Use `all` to show to `rootTitle` as the selected item.
   */
  selectedItem?: string;

  trackUserClick: (data: UserClickData) => void;
};

export const SectionsLayoutMenu: React.FC<SectionsLayoutMenuProps> = ({
  rootTitle,
  mobileLabel,
  pageName,
  rootPath,
  rootPageName,
  sections,
  selectedItem,
  trackUserClick,
}) => {
  const router = useRouter();
  return (
    <LayoutMenu
      aria-label={mobileLabel}
      closeLabel={`Close ${mobileLabel}`}
      mobileButtonText={`${mobileLabel} →`}
      onSectionToggle={(sectionSlug) =>
        trackUserClick({
          page_name: pageName,
          context: 'side_menu',
          target: `${sectionSlug}_collapse`,
        })
      }
      sections={sections.map((section) => ({
        items: section.items.map((item) => ({
          ...item,
          onClick: (event) => {
            event.preventDefault();
            trackUserClick({
              page_name: pageName,
              target: item.slug,
              context: 'side_menu',
              href: item.href,
            });
            return router.push(item.href);
          },
        })),
        slug: section.slug,
        title: section.title,
      }))}
      selectedItem={selectedItem}
    >
      {selectedItem === 'all' ? (
        <SelectedSectionItem>{rootTitle}</SelectedSectionItem>
      ) : (
        <SectionItemLink
          href={rootPath}
          onClick={(event) => {
            event.preventDefault();
            trackUserClick({
              page_name: pageName,
              context: 'side_menu',
              target: `${rootPageName}_homepage`,
            });
            return router.push(rootPath);
          }}
        >
          {rootTitle}
        </SectionItemLink>
      )}
    </LayoutMenu>
  );
};
