import {
  Badge,
  Box,
  Card,
  FlexBox,
  IconButton,
  StrokeButton,
  Text,
} from '@codecademy/gamut';
import { CloseIcon } from '@codecademy/gamut-icons';
import { CustomProject } from '@codecademy/gamut-illustrations';
import React from 'react';

type CustomProjectCardProps = {
  showClosingMessage?: boolean | undefined;
  isDissmissable: boolean;
  cta: React.ReactNode;
  onDismissClosingMessage?: () => void;
  onDismissCard?: () => void;
};

export const CustomProjectCard: React.FC<CustomProjectCardProps> = ({
  showClosingMessage,
  onDismissClosingMessage,
  isDissmissable,
  onDismissCard,
  cta,
}) => {
  return (
    <Card p={24} aria-live="assertive">
      {showClosingMessage ? (
        <FlexBox alignItems="center" justifyContent="space-between">
          <Text>
            Message hidden. Try custom projects anytime by going to the in the
            projects tab.
          </Text>
          <StrokeButton
            variant="secondary"
            aria-label="Got it, dismiss message"
            onClick={onDismissClosingMessage}
          >
            Got it
          </StrokeButton>
        </FlexBox>
      ) : (
        <FlexBox alignItems="center">
          <Box bg="background-hover" borderRadius="full" p={12}>
            <Box height={34} width={38}>
              <CustomProject />
            </Box>
          </Box>
          <Box ml={24}>
            <FlexBox gap={12} alignItems="center" mb={4}>
              <Text as="h2" variant="p-base" fontWeight="bold">
                Custom Projects
              </Text>
              <Badge size="sm" variant="secondary">
                Beta
              </Badge>
            </FlexBox>
            <Text>
              Select a concept to practice and the industry of your choice. Get
              a new, AI-generated custom project every time.
            </Text>
          </Box>
          {cta ? <Box ml={48}>{cta}</Box> : null}
          {isDissmissable && (
            <IconButton
              tip="Dismiss"
              size="small"
              ml={12}
              icon={CloseIcon}
              onClick={onDismissCard}
            />
          )}
        </FlexBox>
      )}
    </Card>
  );
};
