import { Box, FlexBox } from '@codecademy/gamut';
import { system } from '@codecademy/gamut-styles';
import { StyleProps } from '@codecademy/variance';
import { Scale } from '@codecademy/variance/dist/types/config';
import * as React from 'react';

import { SectionItem } from './AccordionMenu';
import { SectionItemLink } from './SectionItemLink';
import { SelectedSectionItem } from './SelectedSectionItem';

export type LayoutMenuSectionStyles = StyleProps<typeof system.space>;

export type LayoutMenuSectionProps = LayoutMenuSectionStyles & {
  items: SectionItem[];
  selectedItem?: string;
  onItemClick: () => void;
  verticalSpacing?: Scale<{
    readonly property: 'padding';
    readonly scale: 'spacing';
  }>;
};

export const LayoutMenuSection: React.FC<LayoutMenuSectionProps> = ({
  items,
  selectedItem,
  onItemClick,
  verticalSpacing = 8,
  ...styleProps
}) => (
  <Box {...styleProps}>
    {items.map((item) => (
      <Box key={item.slug} py={verticalSpacing} px={4}>
        {selectedItem === item.slug ? (
          <SelectedSectionItem>
            <FlexBox alignItems="center">
              {item.title}
              {item.badge}
            </FlexBox>
          </SelectedSectionItem>
        ) : (
          <SectionItemLink
            href={item.href}
            onClick={(event) => {
              item.onClick(event);
              onItemClick();
            }}
          >
            <FlexBox alignItems="center">
              {item.title}
              {item.badge}
            </FlexBox>
          </SectionItemLink>
        )}
      </Box>
    ))}
  </Box>
);
