import { EnrollmentStatus } from './types';

export const getPathImageUrl = (
  enrollment: EnrollmentStatus,
  imageUrl: string
) => {
  if (enrollment === EnrollmentStatus.InProgress) {
    return imageUrl.replace('-inProgress', '');
  }
  if (enrollment === EnrollmentStatus.Completed) {
    return imageUrl.replace('-completed', '');
  }
  return imageUrl;
};

export const getTimeToCompleteDisplayAmount = (amount: number) =>
  amount <= 0 ? '< 1' : amount;

export const getTimeToCompleteDisplayDuration = (amount: number) =>
  amount <= 1 ? 'hour' : 'hours';
