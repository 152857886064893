import { Box } from '@codecademy/gamut';
import { CodeByteEditor } from '@mono/codebytes';
import { Language } from 'prism-react-renderer';

import {
  getCodebyteLanguage,
  getEditorLanguage,
} from '../../ContentMarkdown/languages';
import {
  ErrorBlockVariant,
  MathBlockVariant,
  PseudoBlockVariant,
} from '../BlockVariants';
import { ColorizedBlock } from '../ColorizedBlock';

export type CodeBlockProps = {
  language?: string;
  children: string;
};

export const CodeBlock: React.FC<CodeBlockProps> = ({ language, children }) => {
  return language?.startsWith('codebyte/') ? (
    <Box fontFamily="base">
      <CodeByteEditor
        title="codebyte"
        text={children}
        language={getCodebyteLanguage(language)}
        hideCopyButton
        snippetsBaseUrl={process.env.NEXT_PUBLIC_SNIPPETS_BASE_URL}
        maxWidth="100%"
      />
    </Box>
  ) : language === 'error' ? (
    <ErrorBlockVariant>{children}</ErrorBlockVariant>
  ) : language === 'pseudo' ? (
    <PseudoBlockVariant>{children}</PseudoBlockVariant>
  ) : language === 'tex' ? (
    <MathBlockVariant>{children}</MathBlockVariant>
  ) : (
    <ColorizedBlock
      text={children}
      language={
        language ? (getEditorLanguage(language) as Language) : undefined
      }
    />
  );
};
