import { Anchor, HeadingTags } from '@codecademy/gamut';
import { CareerPathCard, CourseCard, SkillPathCard } from '@mono/brand';
import {
  CurriculumCardData,
  isCourseData,
  isPathData,
  PathGoalEnum,
} from '@mono/data/curriculum-cards';
import { useTracking } from '@mono/data/use-tracking';
import { userIsLoggedIn, useUser } from '@mono/data/user';
import { UserClickData } from '@mono/tracking';
import { forwardRef, PropsWithRef } from 'react';

export interface VariableCurriculumCardProps {
  content: CurriculumCardData;
  isFullSize?: boolean;
  headingLevel?: HeadingTags;
  trackingData: Omit<Partial<UserClickData>, 'slug'>;
}

export const VariableCurriculumCard = forwardRef<
  HTMLAnchorElement,
  PropsWithRef<VariableCurriculumCardProps>
>(({ content, isFullSize = false, headingLevel = 'h3', trackingData }, ref) => {
  const isCourse = isCourseData(content);
  const isPath = isPathData(content);

  const { trackUserClick } = useTracking();

  const isSkillPath = isPath && content.goal === PathGoalEnum.Skill;

  const isCareerPath = isPath && content.goal === PathGoalEnum.Career;

  const target = `${
    isCourse ? 'course' : isSkillPath ? 'skillpath' : 'careerpath'
  }_card`;

  const href = content.urlPath;

  const user = useUser();
  const isLoggedInUser = user && userIsLoggedIn(user);
  const isProUser = (isLoggedInUser && user.is_pro) ?? false;
  const isInOpenFirstModuleVariant =
    user?.experiments?.open_first_module === 'variant';

  return (
    <Anchor
      href={href}
      onClick={() => {
        trackUserClick({
          target,
          href,
          ...trackingData,
          slug: content.slug,
        });
      }}
      variant="interface"
      height="100%"
      width="100%"
      ref={ref}
    >
      {isCourse && (
        <CourseCard
          {...content}
          isFullSize={isFullSize}
          headingLevel={headingLevel}
          isProUser={isProUser}
          isInOpenFirstModuleVariant={isInOpenFirstModuleVariant}
        />
      )}
      {isSkillPath && (
        <SkillPathCard
          {...content}
          isFullSize={isFullSize}
          headingLevel={headingLevel}
        />
      )}
      {isCareerPath && (
        <CareerPathCard
          {...content}
          isFullSize={isFullSize}
          headingLevel={headingLevel}
        />
      )}
    </Anchor>
  );
});
