import { Box, Column, ColumnProps, GridBox } from '@codecademy/gamut';
import React, { ReactElement } from 'react';

export const ScaleChart: React.FC<{
  scale: number;
  display: ColumnProps['display'];
}> = ({ scale, display }) => {
  const scaleLines: ReactElement[] = [];
  for (let i = 0; i < scale; i++) {
    scaleLines.push(
      <Column size={1} justifyItems="center" key={i}>
        <Box
          borderColorLeft="background-disabled"
          borderStyleLeft="solid"
          borderWidthLeft={1}
          height="100%"
        />
      </Column>
    );
  }
  return (
    <Column gridColumnStart={2} gridRowStart={1} size={1} display={display}>
      <GridBox
        gridTemplateColumns={{ _: `repeat(${scale}, 1fr)` }}
        height="100%"
      >
        {scaleLines}
      </GridBox>
    </Column>
  );
};
