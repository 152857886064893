import { useMemo } from 'react';

import { calculateTicksAndRange, getHighestExperience } from './helpers';
import { SkillTag } from './types';

export const useNormalizeSkills = (
  skills: SkillTag[],
  contentItemExpValue = 0
) => {
  const highestExperience = useMemo(() => {
    return getHighestExperience(skills);
  }, [skills]);

  const [tickCount, niceMin, niceMax] = calculateTicksAndRange(
    5,
    0,
    highestExperience + contentItemExpValue
  );

  return {
    highestExperience,
    chartData: { tickCount, niceMin, niceMax },
  };
};
