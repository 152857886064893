// start with this since it's more human-readable, then invert it since that's how we'll

import { CodeByteEditorProps } from '@mono/codebytes';

// actually index it; export for testing purposes
export const languageToAliases: Record<string, string[]> = {
  c: [],
  cpp: ['hpp', 'cc', 'hh', 'c++', 'h++', 'cxx', 'hxx'],
  csharp: ['c#', 'cs'],
  css: [],
  go: ['golang'],
  html: [],
  java: [],
  javascript: ['js', 'jsx', 'ts'],
  markdown: ['md'],
  php: [],
  plaintext: [],
  python: ['py', 'gyp'],
  ruby: ['rb', 'gemspec', 'podspec', 'thor', 'irb'],
  scheme: [],
  shell: [],
  sql: [],
  swift: [],
};

const aliasToLanguage = Object.entries(languageToAliases).reduce(
  (acc, [language, aliases]) => {
    aliases.forEach((alias) => acc.set(alias, language));
    return acc;
  },
  new Map<string, string>()
);

export const getCodebyteLanguage = (
  codeBlockLabel: string
): CodeByteEditorProps['language'] => {
  const alias = codeBlockLabel.replace('codebyte/', '').toLowerCase();
  const language = aliasToLanguage.get(alias) ?? alias;
  return languageToAliases[language]
    ? (language as CodeByteEditorProps['language'])
    : '';
};

export const getEditorLanguage = (language: string) => {
  const editorLanguage = aliasToLanguage.get(language) || language;
  const isShellOrBash = editorLanguage === 'shell' || editorLanguage === 'bash';
  return isShellOrBash ? 'plaintext' : editorLanguage;
};
