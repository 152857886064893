import { Text, TextProps } from '@codecademy/gamut';

export const BaseBlock: React.FC<
  Pick<TextProps, 'bg' | 'textColor'> & {
    dataTestId?: string;
  } & { children: React.ReactNode }
> = ({ bg, children, dataTestId, textColor }) => (
  <Text
    data-testid={dataTestId}
    as="code"
    bg={bg}
    fontSize={14}
    overflowX="auto"
    p={16}
    textColor={textColor}
    width="100%"
  >
    {children}
  </Text>
);
