import { Box, FlexBox, Text } from '@codecademy/gamut';
import { CertificateIcon, UnlockIcon } from '@codecademy/gamut-icons';
import { CheckerDense } from '@codecademy/gamut-patterns';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import pluralize from 'pluralize';

const DottedLine = styled(CheckerDense)(
  css({
    height: '1px',
    display: 'flex',
    position: 'absolute',
    marginTop: '-1px',
  })
);

export const Divider: React.FC = () => {
  return (
    // the height and position properties are necessary for iOS devices
    <Box py={4} height={0} position="relative">
      <DottedLine />
    </Box>
  );
};

export const CertificateComponent: React.FC<{ professionalCert?: boolean }> = ({
  professionalCert,
}) => {
  const certificateType = professionalCert
    ? `Professional Certification`
    : `Certificate`;
  return (
    <>
      <FlexBox alignItems="center">
        <FlexBox pr={4}>
          <CertificateIcon />
        </FlexBox>
        <Text variant="p-small" pl={4} aria-label={`With ${certificateType}`}>
          With <b>{certificateType}</b>
        </Text>
      </FlexBox>
    </>
  );
};

export const FreeModuleComponent: React.FC = () => {
  return (
    <>
      <FlexBox alignItems="center">
        <FlexBox pr={4}>
          <UnlockIcon />
        </FlexBox>
        <Text variant="p-small" pl={4}>
          Start course with your Basic plan
        </Text>
      </FlexBox>
    </>
  );
};

export const CourseCountComponent: React.FC<{ count: number }> = ({
  count,
}) => {
  const label = pluralize('Courses', count);

  return (
    <Text variant="p-small" aria-label={`Includes ${count} ${label}`}>
      Includes{' '}
      <b>
        {count} {label}
      </b>
    </Text>
  );
};
