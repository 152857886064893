import { GridBoxProps } from '@codecademy/gamut/dist/Box/props';
import { theme } from '@codecademy/gamut-styles';
import * as React from 'react';

import { calculateExperienceBarWidth } from '../helpers';
import {
  ExperienceBar,
  ExperienceBarWrapper,
  ForegroundExperienceBar,
  minProgressBarWith,
} from './Bars';

type BaseSkillsExperienceBarProps = {
  experience?: number;
  maxExperience: number;
  tickCount: number;
  gridColumnStart: GridBoxProps['gridColumnStart'];
  variant?: 'dashboard' | 'interstitial' | 'default';
};

type SkillsExperienceBarWithProgressProps = BaseSkillsExperienceBarProps & {
  showProgress: boolean;
  experienceGained: number;
  index: number;
};

type SkillsExperienceBarWithNoProgressProps = BaseSkillsExperienceBarProps & {
  showProgress?: never;
  experienceGained?: never;
  index?: never;
};
export type SkillsExperienceBarProps =
  | SkillsExperienceBarWithProgressProps
  | SkillsExperienceBarWithNoProgressProps;

export const SkillsExperienceBar: React.FC<SkillsExperienceBarProps> = ({
  experience = 0,
  experienceGained,
  maxExperience,
  tickCount,
  showProgress,
  gridColumnStart,
  index,
  variant = 'default',
}) => {
  const showExperienceBar = showProgress || experience > 0;
  const showForegroundExperienceBar = showProgress && experience > 0;

  const experienceBarWidth = calculateExperienceBarWidth({
    experience: experience + (experienceGained ?? 0),
    maxExperience,
  });

  const foregroundExperienceBarWidth = calculateExperienceBarWidth({
    experience,
    maxExperience,
  });

  const initialExperienceBarWidth = `${Math.max(
    minProgressBarWith,
    foregroundExperienceBarWidth
  )}%`;

  const endExperienceBarWidth = `${Math.max(
    minProgressBarWith,
    experienceBarWidth
  )}%`;

  const animationProps =
    index !== undefined
      ? {
          initial: { width: initialExperienceBarWidth },
          animate: {
            width: endExperienceBarWidth,
          },
          transition: { duration: 0.25, delay: 0.75 * index },
        }
      : {};
  const styleProps =
    index === undefined
      ? {
          width: endExperienceBarWidth,
        }
      : {};

  return (
    showExperienceBar && (
      <ExperienceBarWrapper
        aria-live="polite"
        gridColumnStart={gridColumnStart}
        gridColumnEnd={tickCount * 2}
      >
        <ExperienceBar
          {...animationProps}
          style={{
            ...styleProps,
            backgroundColor:
              variant === 'dashboard'
                ? theme.colors['yellow-400']
                : theme.colors.yellow,
          }}
          data-testid="background-experience-bar"
        />
        {showForegroundExperienceBar && (
          <ForegroundExperienceBar
            width={initialExperienceBarWidth}
            data-testid="foreground-progress-bar"
            variant={variant}
          />
        )}
      </ExperienceBarWrapper>
    )
  );
};
