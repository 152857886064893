import {
  ArtificialIntelligenceIcon,
  BashShellIcon,
  CIcon,
  CloudComputingIcon,
  ComputerScienceIcon,
  CPlusIcon,
  CSharpIcon,
  CybersecurityIcon,
  DataAnalyticsIcon,
  DataEngineeringIcon,
  DataScienceIcon,
  DataVisualizationIcon,
  DevopsIcon,
  FallbackSkillIcon,
  FlutterIcon,
  GameDevelopmentIcon,
  GoIcon,
  HtmlCssIcon,
  ItIcon,
  JavaIcon,
  JavascriptIcon,
  KotlinIcon,
  MachineLearningIcon,
  MathIcon,
  MobileDevelopmentIcon,
  PhpIcon,
  PythonIcon,
  RIcon,
  RubyIcon,
  SqlIcon,
  SwiftIcon,
  WebDesignIcon,
  WebDevelopmentIcon,
} from '@codecademy/gamut-icons';

export const skillTagIconsMap: Record<string, typeof FallbackSkillIcon> = {
  'artificial-intelligence': ArtificialIntelligenceIcon,
  bash: BashShellIcon,
  c: CIcon,
  'c-plus-plus': CPlusIcon,
  'c-sharp': CSharpIcon,
  'cloud-computing': CloudComputingIcon,
  'computer-science': ComputerScienceIcon,
  cybersecurity: CybersecurityIcon,
  'data-analytics': DataAnalyticsIcon,
  'data-engineering': DataEngineeringIcon,
  'data-science': DataScienceIcon,
  'data-visualization': DataVisualizationIcon,
  devops: DevopsIcon,
  flutter: FlutterIcon,
  'game-development': GameDevelopmentIcon,
  go: GoIcon,
  'html-css': HtmlCssIcon,
  'information-technology': ItIcon,
  java: JavaIcon,
  javascript: JavascriptIcon,
  kotlin: KotlinIcon,
  'machine-learning': MachineLearningIcon,
  math: MathIcon,
  'mobile-development': MobileDevelopmentIcon,
  php: PhpIcon,
  python: PythonIcon,
  r: RIcon,
  ruby: RubyIcon,
  sql: SqlIcon,
  swift: SwiftIcon,
  'web-design': WebDesignIcon,
  'web-development': WebDevelopmentIcon,
  default: FallbackSkillIcon,
};
