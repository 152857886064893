import { Column, FlexBox, GridBox } from '@codecademy/gamut';
import { motion } from 'framer-motion';

import { ExperienceValuesWithProgress } from './ExperienceValuesWithProgress';
import { numDigits } from './helpers';
import { ScaleChart } from './ScaleChart';
import { ScaleChartHeader } from './ScaleChartHeader';
import { SkillsExperienceBar } from './SkillsExperienceBar/SkillsExperienceBar';
import { SkillTitle } from './SkillTitle';
import { SkillTag } from './types';
import { useNormalizeSkills } from './useNormalizeSkills';

const experienceValueColumnBaseSize = (experience = 3) => {
  const digits = numDigits(experience);
  return {
    sm: digits > 4 ? 5 : 4,
    md: digits > 4 ? 5 : 4,
    lg: digits > 4 ? 4 : 5,
    xl: digits > 4 ? 5 : 4,
  };
};

export const SkillsExperienceChartWithIndividualProgress: React.FC<{
  contentItemSkills: SkillTag[];
  variant?: 'interstitial' | 'dashboard';
}> = ({ contentItemSkills, variant }) => {
  const latestExperience = contentItemSkills.reduce((acc, skill) => {
    if (skill.latestExperience) {
      return [...acc, skill.latestExperience];
    }
    return [...acc];
  }, []);

  const maxGainedExperience = Math.max(...latestExperience);

  const {
    highestExperience,
    chartData: { tickCount, niceMin, niceMax },
  } = useNormalizeSkills(contentItemSkills, maxGainedExperience);
  const newExperience = highestExperience + maxGainedExperience;
  const highestExperienceDigits = numDigits(newExperience);

  const templateColumns = {
    sm: `5fr 5fr ${experienceValueColumnBaseSize(newExperience).sm}fr`,
    md: `5fr 5fr ${experienceValueColumnBaseSize(newExperience).md}fr`,
    lg: `5fr 5fr ${experienceValueColumnBaseSize(newExperience).lg}fr`,
    xl: `5fr 5fr ${experienceValueColumnBaseSize(newExperience).xl}fr`,
  };

  return (
    <GridBox
      width="100%"
      gridTemplateColumns={{
        _: '1fr',
        ...templateColumns,
      }}
      as="ul"
      pl={0}
      mb={0}
    >
      <ScaleChartHeader
        labelCount={tickCount}
        min={niceMin}
        max={niceMax}
        gridTemplateColumns={{
          _: 'minmax(100px, 1fr) minmax(195px, 1fr)',
          ...templateColumns,
        }}
        display={{ _: 'none', sm: 'grid' }}
      />
      {contentItemSkills.map((skill, index) => {
        const { id, experience, latestExperience } = skill;
        return (
          <GridBox
            key={id}
            gridColumnEnd="span 3"
            gridTemplateColumns={{
              _: 'minmax(100px, 1fr) minmax(195px, 1fr)',
              ...templateColumns,
            }}
            gridTemplateRows={{ _: '38px 38px', sm: '1fr' }}
            bg={index % 2 === 1 ? 'background-selected' : 'initial'}
            as="li"
          >
            <FlexBox
              alignItems="end"
              py={{ sm: 12 }}
              gridColumn="span 1"
              pl={16}
            >
              <SkillTitle
                skill={skill}
                showNewBadge={
                  latestExperience !== undefined && experience === 0
                }
              />
            </FlexBox>
            <ScaleChart scale={tickCount} display={{ _: 'none', sm: 'grid' }} />
            <Column
              gridColumnStart={{ _: 1, sm: 2 }}
              gridRowStart={{ _: 2, sm: 1 }}
              size={{ _: 2, sm: 1 }}
              mt={{ _: 8, sm: 16 }}
              pl={{ _: 48, sm: 0 }}
              pr={{ _: 16, sm: 0 }}
              gridTemplateColumns={{
                _: '1fr',
                sm: `repeat(${tickCount * 2}, 1fr)`,
              }}
            >
              <SkillsExperienceBar
                maxExperience={niceMax}
                experience={experience}
                experienceGained={latestExperience ?? 0}
                tickCount={tickCount}
                showProgress
                gridColumnStart={{ _: 1, sm: 2 }}
                index={index}
                variant={variant}
              />
            </Column>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.75 * index }}
            >
              <ExperienceValuesWithProgress
                experienceProgress={latestExperience ?? 0}
                experience={experience}
                highestExperienceDigits={highestExperienceDigits}
              />
            </motion.div>
          </GridBox>
        );
      })}
    </GridBox>
  );
};
