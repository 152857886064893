import { Column, FlexBox, GridBox } from '@codecademy/gamut';

import { ExperienceValues } from './ExperienceValues';
import { ScaleChart } from './ScaleChart';
import { ScaleChartHeader } from './ScaleChartHeader';
import { SkillsExperienceBar } from './SkillsExperienceBar/SkillsExperienceBar';
import { SkillTitle } from './SkillTitle';
import { SkillTag } from './types';
import { useNormalizeSkills } from './useNormalizeSkills';

export const ModalSkillsExperienceChart: React.FC<{
  contentItemSkills: SkillTag[];
  variant?: 'interstitial' | 'dashboard';
}> = ({ contentItemSkills, variant }) => {
  const {
    chartData: { tickCount, niceMin, niceMax },
  } = useNormalizeSkills(contentItemSkills);

  const templateColumns = {
    sm: 'minmax(110px, 4fr) 5fr 3fr',
  };

  return (
    <GridBox
      width="100%"
      gridTemplateColumns={{
        _: '1fr',
        ...templateColumns,
      }}
      as="ul"
      pl={0}
      mb={0}
    >
      <ScaleChartHeader
        labelCount={tickCount}
        min={niceMin}
        max={niceMax}
        gridTemplateColumns={{
          _: 'minmax(100px, 1fr) minmax(100px, 1fr)',
          ...templateColumns,
        }}
        display={{ _: 'none', sm: 'grid' }}
      />
      {contentItemSkills.map((skill, index) => {
        const { id, experience } = skill;
        return (
          <GridBox
            key={id}
            gridColumnEnd="span 3"
            gridTemplateColumns={{
              _: 'minmax(100px, 1fr) minmax(100px, 1fr)',
              ...templateColumns,
            }}
            gridTemplateRows={{ _: '38px 38px', sm: '1fr' }}
            bg={index % 2 === 1 ? 'background-selected' : 'initial'}
            width="100%"
            as="li"
          >
            <FlexBox
              alignItems="end"
              py={{ sm: 12 }}
              gridColumn="span 1"
              pl={16}
            >
              <SkillTitle skill={skill} />
            </FlexBox>

            <ScaleChart scale={tickCount} display={{ _: 'none', sm: 'grid' }} />
            <Column
              gridColumnStart={{ _: 1, sm: 2 }}
              gridRowStart={{ _: 2, sm: 1 }}
              size={{ _: 2, sm: 1 }}
              mt={{ _: 0, sm: 16 }}
              pl={{ _: 48, sm: 0 }}
              pr={{ _: 16, sm: 0 }}
              gridTemplateColumns={{
                _: '1fr',
                sm: `repeat(${tickCount * 2}, 1fr)`,
              }}
              pt={{ _: 8, sm: 0 }}
            >
              <SkillsExperienceBar
                maxExperience={niceMax}
                experience={experience - (skill.latestExperience ?? 0)}
                tickCount={tickCount}
                gridColumnStart={{ _: 1, sm: 2 }}
                experienceGained={skill.latestExperience ?? 0}
                showProgress
                index={index}
                variant={variant}
              />
            </Column>
            <ExperienceValues experience={experience} py={{ sm: 12 }} />
          </GridBox>
        );
      })}
    </GridBox>
  );
};
