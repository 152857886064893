import { HeadingTags } from '@codecademy/gamut';

import { ContentGroupBaseCard } from '../ContentGroupBaseCard';
import {
  CertificateComponent,
  Divider,
  FreeModuleComponent,
} from '../ContentGroupBaseCard/shared';
import {
  CourseDifficulty,
  EnrollmentStatus,
} from '../ContentGroupBaseCard/types';

export type CourseCardProps = {
  title: string;
  lessonCount: number;
  contentModuleIds: string[];
  grantsCertificate: boolean;
  enrollmentStatus: EnrollmentStatus;
  pro: boolean;
  shortDescription?: string | null;
  difficulty?: CourseDifficulty | null;
  isFullSize?: boolean;
  headingLevel?: HeadingTags;
  timeToComplete?: number | null;
  isProUser?: boolean;
  isInOpenFirstModuleVariant?: boolean;
};

export const CourseCard: React.FC<CourseCardProps> = ({
  title,
  lessonCount,
  contentModuleIds,
  grantsCertificate,
  shortDescription,
  difficulty,
  enrollmentStatus,
  pro,
  isFullSize,
  headingLevel = 'h3',
  timeToComplete,
  isProUser = false,
  isInOpenFirstModuleVariant = false,
}) => {
  // only show certificates for pro exclusive courses, since only pro users can do pro courses & only pro users can gain certificates
  // edge case here: pro users who are logged in wont be able to tell - from the card - that a free course can grant them a cert as well
  const showCertificate = grantsCertificate && pro;

  // after the experiment is over we can just use the user pro status and module count
  const showFreeFirstModule =
    !isProUser &&
    isInOpenFirstModuleVariant &&
    pro &&
    contentModuleIds.length > 1;

  return (
    <ContentGroupBaseCard
      headerBackgroundColor="green-100"
      headingLevel={headingLevel}
      headerText={pro ? 'Course' : 'Free course'}
      title={title}
      description={shortDescription}
      difficulty={difficulty}
      numLessons={lessonCount}
      isFullSize={isFullSize}
      imageSrc="https://static-assets.codecademy.com/Courses/Course-Cards/pillars.svg"
      enrollmentStatus={enrollmentStatus}
      timeToComplete={timeToComplete}
    >
      {showFreeFirstModule && (
        <>
          <Divider />
          <FreeModuleComponent />
        </>
      )}
      {showCertificate && (
        <>
          <Divider />
          <CertificateComponent />
        </>
      )}
    </ContentGroupBaseCard>
  );
};
