import { FlexBox, FlexBoxProps, Text } from '@codecademy/gamut';
import React from 'react';

import { formatNumberUS } from './helpers';

export const ExperienceValues: React.FC<{
  experience: number;
  py: FlexBoxProps['py'];
}> = ({ experience, py }) => {
  const experienceCopy = `${formatNumberUS(experience)} XP`;

  return (
    <FlexBox py={py} justifyContent="flex-end" alignItems="end" pr={16}>
      <Text textAlign="end" variant="p-base" color="navy-600" ml={24}>
        {experienceCopy}
      </Text>
    </FlexBox>
  );
};
