/* eslint-disable local-rules/restrict-next-link-import */
/* eslint-disable local-rules/prefer-next-link-hoc  */
import Link from 'next/link';
import React, { ComponentProps } from 'react';

/**
 * NextJS's default implementation of `<Link />` has prefetch of true. This is problematic
 * because it can cause pages to trigger extreme branching of prefetching pages, so we created
 * this new "default" via a thin HOC wrapper.
 *
 * Please use this instead of the vanilla `<Link />`.
 */
export const NextLink: React.FC<ComponentProps<typeof Link>> = (props) => (
  <Link prefetch={false} legacyBehavior {...props} />
);
