import { BlockChildrenProps } from '../../ContentMarkdown/types';
import { BaseBlock } from './BaseBlock';

export const PseudoBlockVariant: React.FC<BlockChildrenProps> = ({
  children,
}) => (
  <BaseBlock bg="gray-200" dataTestId="pseudo-block" textColor="black">
    {children}
  </BaseBlock>
);
