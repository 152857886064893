import { Box, Column, ColumnProps, LayoutGrid } from '@codecademy/gamut';
import React from 'react';

import { ContentTitle } from '../ContentTitle';

type ContentCardGridChild = React.ReactElement<{ isFullSize?: boolean }> | null;

export type ContentCardGridProps = {
  children: ContentCardGridChild[];
  columnSizes?: ColumnProps['size'];
  title?: string;
};

export const ContentCardGrid: React.FC<ContentCardGridProps> = ({
  title,
  children,
  columnSizes = { sm: 6, md: 4 },
}) =>
  React.Children.count(children) === 0 ? null : (
    <Box>
      <ContentTitle>{title}</ContentTitle>
      <LayoutGrid
        data-testid="content-card-grid"
        as="ul"
        columnGap={{ _: 16, sm: 32 }}
        pl={0}
        rowGap={32}
      >
        {React.Children.map(
          children,
          (child: React.ReactElement<{ isFullSize?: boolean }> | null) =>
            child && (
              <Column
                as="li"
                size={columnSizes}
                rowspan={child.props?.isFullSize ? 2 : 1}
              >
                {child}
              </Column>
            )
        )}
      </LayoutGrid>
    </Box>
  );
