import './languages';

import { Text } from '@codecademy/gamut';
import styled from '@emotion/styled';
import Highlight, {
  defaultProps,
  Language,
  Prism as PrismRR,
} from 'prism-react-renderer';
import Prism from 'prismjs';
import React from 'react';

import { codecademyTheme, syntax, ui } from './theme';

type PrismLib = typeof PrismRR & typeof Prism;

export interface ColorizedBlockProps {
  language?: Language;
  text: string;
  className?: string;
}

const StyledContainer = styled.pre`
  padding: 1rem;
  background: #211e2f;
  margin-bottom: 0;
  color: ${syntax.white};
  background-color: ${ui.background};
  font-family: Menlo, Monaco, 'Courier New', monospace;
  font-size: 14px;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 24px;
`;

export const ColorizedBlock: React.FC<ColorizedBlockProps> = ({
  language,
  text = '',
  className,
}) => {
  if (language) {
    return (
      <Highlight
        {...defaultProps}
        code={text}
        theme={codecademyTheme}
        language={language}
        Prism={Prism as PrismLib}
      >
        {({ tokens, getLineProps, getTokenProps }) => (
          <StyledContainer
            role="region"
            aria-label="code snippet"
            data-testid="colorized-block"
            className={className}
          >
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </StyledContainer>
        )}
      </Highlight>
    );
  }
  return <Text as="code">{text}</Text>;
};
