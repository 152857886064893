import { Text } from '@codecademy/gamut';
import React from 'react';

export type ContentTitleProps = {
  children?: React.ReactNode;
};

export const ContentTitle: React.FC<ContentTitleProps> = ({ children }) =>
  children ? (
    <Text as="h2" fontSize={26} pb={16}>
      {children}
    </Text>
  ) : null;
