import { Column, GridBox, Text } from '@codecademy/gamut';
import { GridBoxProps } from '@codecademy/gamut/dist/Box/props';
import React, { ReactElement } from 'react';

import { formatNumberUSCompact } from './helpers';

export const getLabel = (
  labelCount: number,
  labelIndex: number,
  max: number
) => {
  const incrementalDecimal = 100 / (labelCount - 1) / 100;
  return Math.floor(incrementalDecimal * labelIndex * max);
};

export const ScaleChartHeader: React.FC<{
  min: number;
  max: number;
  labelCount: number;
  gridTemplateColumns: GridBoxProps['gridTemplateColumns'];
  display: GridBoxProps['display'];
}> = ({ labelCount, min, max, gridTemplateColumns, display }) => {
  const gridColumns = labelCount;
  const scaleLabels: ReactElement[] = [];
  for (let i = min; i < labelCount; i++) {
    scaleLabels.push(
      <Column size={1} justifyItems="center" key={i}>
        <Text data-testid="chart-header-label" aria-hidden>
          {formatNumberUSCompact(getLabel(labelCount, i, max))}
        </Text>
      </Column>
    );
  }
  return (
    <GridBox
      display={display}
      gridColumnEnd="span 3"
      gridTemplateColumns={gridTemplateColumns}
      textColor="text-secondary"
    >
      <Column gridColumnStart={2} gridRowStart={1} size={1}>
        <GridBox
          gridTemplateColumns={{ _: `repeat(${gridColumns}, 1fr)` }}
          height="100%"
        >
          {scaleLabels}
        </GridBox>
      </Column>
    </GridBox>
  );
};
