import { editorColors, theme } from '@codecademy/gamut-styles';
import { PrismTheme } from 'prism-react-renderer';

export const syntax = {
  gray: editorColors.gray,
  green: editorColors.green,
  purple: editorColors.purple,
  red: editorColors.red,
  white: theme.colors.white,
  yellow: editorColors.yellow,
  lightGray: theme.colors['gray-300'],
  orange: editorColors.orange,
};

export const ui = {
  background: '#211E2F',
};

// Selectors based off prismjs default theme
export const codecademyTheme: PrismTheme = {
  plain: {},
  styles: [
    {
      types: ['prolog'],
      style: {
        color: 'rgb(0, 0, 128)',
      },
    },
    {
      types: ['comment', 'prolog', 'doctype', 'cdata'],
      style: {
        color: syntax.gray,
      },
    },
    {
      types: ['changed', 'keyword', 'interpolation-punctuation'],
      style: {
        color: syntax.purple,
      },
    },
    {
      types: ['inserted', 'attr-value', 'hexcode', 'tag'],
      style: {
        color: syntax.red,
      },
    },
    {
      types: ['constant'],
      style: {
        color: syntax.orange,
      },
    },
    {
      types: ['attr-name', 'variable'],
      style: {
        color: syntax.green,
      },
    },
    {
      types: ['deleted', 'string', 'template-punctuation'],
      style: {
        color: syntax.yellow,
      },
    },
    {
      types: ['selector'],
      style: {
        color: syntax.red,
      },
    },
    {
      // Fix tag color for HTML
      types: ['tag'],
      languages: ['markup'],
      style: {
        color: 'rgb(86, 156, 214)',
      },
    },
    {
      types: ['punctuation', 'operator'],
      style: {
        color: 'rgb(212, 212, 212)',
      },
    },
    {
      // Fix punctuation color for HTML
      types: ['punctuation'],
      languages: ['markup'],
      style: {
        color: '#808080',
      },
    },
    {
      types: ['function'],
      style: {
        color: '#83fff5',
      },
    },
    {
      types: ['class-name', 'parameter', 'function', 'plain', 'number'],
      style: {
        color: syntax.orange,
      },
    },
    {
      types: ['char'],
      style: {
        color: 'rgb(209, 105, 105)',
      },
    },
    {
      types: ['string', 'regex'],
      style: { color: syntax.green },
      languages: ['css'],
    },
    {
      types: ['important', 'bold'],
      style: { fontWeight: 'bold' },
    },
    {
      types: ['italic'],
      style: { fontStyle: 'italic' },
    },
  ],
};
